import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PageHeader from '../../components/PageHeader';
import { TENANT_USER } from '../../utils/constants';
import SparkleCard from '../../components/SparkleCard';
import PieChart from '../../components/Charts/PieChart';
import path from 'path';
import { useFetch } from '../../customHooks/fetchData';
import { getDashboardDataURL } from '../../utils/allurls';
import { getSupportStatsFormattedData } from '../../utils/GeneralUtils';
import ProjectSupportList from '../Dashbord/ProjectSupportList';

export default function TenantUserSupportDashbord() {
  const dispatch = useDispatch();

  console.log(path.resolve(__dirname, './__test__'));
  const dashboardData = useFetch(getDashboardDataURL('support-tenant-dashboard'));

  return (
    <div className='dashboard-container'>
      <PageHeader HeaderText='Support dashboard' Breadcrumb={[{ name: 'Support' }]} />
      <div className={`dashboard-container-summary`}>
        {dashboardData.success &&
          getSupportStatsFormattedData(dashboardData.data.data).map((item, i) => {
            return (
              <SparkleCard
                index={i}
                key={i}
                Heading={item.name}
                Money={`${item.value}${item.valueType}`}
                PerText={''}
                isRandomUpdate={false}
                mainData={item.sparklineData.data}
                chartColor={item.sparklineData.areaStyle.color}
                ContainerClass='col-lg-3 col-md-6 col-sm-6'
              />
            );
          })}
      </div>
      <div className={`dashboard-container-summary`}>
        <ProjectSupportList user_type={TENANT_USER} />
      </div>
    </div>
  );
}
