import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import PageHeader from '../../components/PageHeader';
import { useHistory } from 'react-router';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getDateOneFormatToAnother } from '../../utils/DateUtils';
import { useSelector, useDispatch } from 'react-redux';
import { createProjectScheduleExtendRequest } from '../../actions/projectScheduleExtendRequestAction';
import Message from '../../utils/Message';
import { userId } from '../../utils/userUtils';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useFetch } from '../../customHooks/fetchData';
import { getProjectProfileListURL, getProjectScheduleListURL } from '../../utils/allurls';
import { BASE_HEADERS } from '../../utils/baseValues';
import axios from 'axios';

export default function CreateProjectScheduleExtendRequest() {
  const history = useHistory();
  const dispatch = useDispatch();
  const projectScheduleExtendRequestReducer = useSelector((state) => state.projectScheduleExtendRequestReducer);
  const [projectId, setProjectId] = useState();
  const [scheduleId, setScheduleId] = useState();
  const [scheduleList, setScheduleList] = useState(null);

  const initProjectScheduleExtend = {
    tenant_id: userId(),
    project_id: '',
    schedule_id: '',
    cause_title: '',
    cause_details: '',
    extend_date: '',
    status: 'pending',
  };

  const { loading, data, success } = useFetch(getProjectProfileListURL('approved', userId()));

  const [projectProfileData, setProjectProfileData] = useState(initProjectScheduleExtend);
  const [project_start_date_picker, set_project_start_date_picker] = useState(new Date());

  const handleOnchange = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    setProjectProfileData({
      ...projectProfileData,
      [fieldName]: fieldValue,
    });

    console.log('projectProfileData : ', projectProfileData);
  };

  const projectIdChange = (event) => {
    const projec_id = event.target.value;
    setProjectId(projec_id);

    setProjectProfileData({
      ...projectProfileData,
      project_id: projec_id,
    });

    getScheduleList(projec_id);
  };

  const getScheduleList = async (projec_id) => {
    try {
      const headers = BASE_HEADERS();
      const response = await axios.get(getProjectScheduleListURL(userId(), '', '', projec_id), { headers });

      if (response.status === 200) {
        setScheduleList(response.data.data);
      } else {
        setScheduleList(null);
      }
    } catch (error) {
      setScheduleList(null);
    }
  };

  const scheduleIdChange = (event) => {
    setScheduleId(event.target.value);

    setProjectProfileData({
      ...projectProfileData,
      schedule_id: event.target.value,
    });
  };

  const resetPageData = () => {
    //setProjectProfileData(initProjectScheduleExtend)
  };

  const submitData = (e) => {
    e.preventDefault();
    dispatch(createProjectScheduleExtendRequest(projectProfileData));
  };

  return (
    <div>
      {' '}
      {
        <div className='body'>
          <PageHeader
            HeaderText={`Request project schedule extend`}
            Breadcrumb={[
              { name: 'Project schedule extend', navigate: '' },
              { name: 'Create', navigate: '' },
            ]}
          />
          <h6></h6>
          <div className='row clearfix'>
            <div className='col-lg-6 col-md-12'>
              <div className='form-group '>
                <span>Select profile</span>
                {/* <InputLabel id="demo-select-small form-group">Select project</InputLabel> */}
                <Select className='form-control' labelId='demo-select-small form-group' id='demo-select-small' value={projectId} label='Select project' onChange={projectIdChange}>
                  <MenuItem className='form-group' value='Select project'>
                    <em>Select project</em>
                  </MenuItem>

                  {success &&
                    data.data.map((item, index) => {
                      return <MenuItem value={item.id}>{item.investor_name}</MenuItem>;
                    })}
                </Select>
              </div>
              <div className='form-group '>
                <span>Select profile schedule</span>
                {/* <InputLabel id="demo-select-small form-group">Select project</InputLabel> */}
                <Select className='form-control' labelId='demo-select-small form-group' id='demo-select-small' value={scheduleId} label='Select project schedule' onChange={scheduleIdChange}>
                  <MenuItem className='form-group' value='Select project'>
                    <em>Select work schedule</em>
                  </MenuItem>

                  {scheduleList &&
                    scheduleList.map((item, index) => {
                      return <MenuItem value={item.id}>{item.schedule_name}</MenuItem>;
                    })}
                </Select>
              </div>
              <div className='form-group'>
                <span>What is the reason of project schedule extend request?</span>
                <input className='form-control' placeholder='' name='cause_title' value={projectProfileData.cause_title} type='text' onChange={(e) => handleOnchange(e)} />
              </div>
              <div className='form-group'>
                <span>Details reason</span>
                <input className='form-control' placeholder='' name='cause_details' value={projectProfileData.cause_details} type='text' onChange={(e) => handleOnchange(e)} />
              </div>

              <div className='form-group'>
                <span>Project schedule extended date</span>
                <DatePicker
                  dateFormat='yyyy-MM-dd'
                  name='extend_date'
                  className='form-control'
                  selected={project_start_date_picker}
                  onChange={(date) => {
                    set_project_start_date_picker(date);
                    setProjectProfileData((state) => {
                      return {
                        ...state,
                        extend_date: getDateOneFormatToAnother(date, 'yyyy-MM-DD'),
                      };
                    });
                  }}
                />
              </div>

              <div className='form-group'>
                {projectScheduleExtendRequestReducer.showMessage && !projectScheduleExtendRequestReducer.isLoading && <Message data={projectScheduleExtendRequestReducer.updatedMessageDialog} />}

                {initProjectScheduleExtend && resetPageData()}
              </div>

              <div>
                <Button
                  onClick={(e) => {
                    submitData(e);
                  }}
                  variant='primary'
                >
                  {projectScheduleExtendRequestReducer.loading && <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />}

                  {projectScheduleExtendRequestReducer.loading ? 'Submitting...' : 'Send request'}
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  onClick={(e) => {
                    history.push('project-schedule-extend-dashboard');
                  }}
                  variant='secondary'
                >
                  {'Back to extend request list'}
                </Button>
                <br></br>
                <br></br>
                <br></br>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}
