import { getToken } from './TokenManage';

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;

export const BASE_HEADERS = () => {
  const token = getToken();
  if (token) {
    return {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Content-Security-Policy': 'upgrade-insecure-requests',
      Authorization: 'Bearer ' + token,
    };
  }

  return {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Content-Security-Policy': 'upgrade-insecure-requests',
  };
};

export const BASE_HEADERS_FILE_TYPE = () => {
  const token = getToken();
  if (token) {
    return {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      'Content-Security-Policy': 'upgrade-insecure-requests',
      Authorization: 'Bearer ' + token,
    };
  }

  return {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json',
    'Content-Security-Policy': 'upgrade-insecure-requests',
  };
};
