import React, { useState } from 'react';
import ICON_LOGIN_BTN from '../../assets/images/icons/trash.svg';
import ICON_PDF_BTN from '../../assets/images/icons/icon-operation-menu.png';
import { getFormattedAccountType } from '../../utils/GeneralUtils';
import { formatDateValue } from '../../utils/DateUtils';
import axios from 'axios';
import { userDeleteApiEndpoint } from '../../utils/allurls';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

export default function UserRow(props) {
  const { user } = props;
  const [show, setShow] = useState(false);
  const [activeId, setActiveId] = useState(null);

  const handleClose = () => {
    setShow(false);
    setActiveId(null);
  };
  const handleShow = (id) => {
    setActiveId(id);
    setShow(true);
  };

  const handleConfirm = () => {
    handleClickOnDelete(activeId);
    handleClose();
  };

  return (
    <>
      <tr>
        <th scope='row'>{user.id}</th>
        <td>{user.name}</td>
        <td>{user.email}</td>
        <td>{user.description}</td>
        <td>{formatDateValue(user.createdAt)}</td>
        <td>{getFormattedAccountType(user.user_type) == 'Tenant' ? 'Investor' : getFormattedAccountType(user.user_type)}</td>
        <td>
          <div role='button' onClick={() => handleShow(user.id)} className='tenant-row-actions '>
            <img width='15px' height='15px' src={ICON_LOGIN_BTN} />
          </div>
        </td>
      </tr>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className='body'>
            <h3
              style={{
                border: '1px solid #8080801f',
                borderRadius: '100%',
                width: '200px',
                height: '200px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                margin: 'auto',
              }}
            >
              Are you want to delete?
            </h3>
            <div className='d-flex justify-content-around'>
              <Button onClick={handleClose}>Cancel</Button>
              <Button className='btn btn-danger' onClick={handleConfirm}>
                Confirm
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export async function handleClickOnDelete(id) {
  const reqUrl = userDeleteApiEndpoint(id);
  const res = await axios.delete(reqUrl);
  if (res.status === 200 || true) {
    window.location.reload();
  } else alert('Some thing is wrong');
}
