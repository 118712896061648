import React from 'react';
import { TENANT_USER, ADMIN_USER } from '../../utils/constants';
import { useFetch } from '../../customHooks/fetchData';
import { getDashboardDataURL } from '../../utils/allurls';
import { getScheduleStatsFormattedData } from '../../utils/GeneralUtils';
import SparkleCard from '../../components/SparkleCard';

export default function ScheduleStatsSummary(props) {
  //schedule-tenant-dashboard,schedule-admin-dashboard

  var requestURL = 'schedule-tenant-dashboard';

  if (props.user_type === TENANT_USER) {
    requestURL = 'schedule-tenant-dashboard';
  } else if (props.user_type === ADMIN_USER) {
    requestURL = 'schedule-admin-dashboard';
  }
  const dashboardData = useFetch(getDashboardDataURL(requestURL));

  return (
    <>
      {dashboardData.success &&
        getScheduleStatsFormattedData(dashboardData.data.data).map((item, i) => {
          return (
            <SparkleCard
              index={i + 200}
              key={i + 200}
              Heading={item.name}
              Money={`${item.value}${item.valueType}`}
              PerText={''}
              isRandomUpdate={false}
              mainData={item.sparklineData.data}
              chartColor={item.sparklineData.areaStyle.color}
              ContainerClass='col-lg-3 col-md-6 col-sm-6 p-1'
            />
          );
        })}
    </>
  );
}
