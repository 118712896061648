import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Dropdown, Nav, Toast } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { onPressDashbord, onPressDashbordChild, onPressThemeColor, onPressGeneralSetting, onPressNotification, onPressEqualizer, onPressSideMenuToggle, onPressMenuProfileDropdown, onPressSideMenuTab, tostMessageLoad } from '../actions';
import { userLogout, loginDataReset } from '../actions/LoginAction';
import ProfileImage from '../assets/images/profile_avatar.png';
import Avatar4 from '../assets/images/xs/avatar4.jpg';
import Avatar5 from '../assets/images/xs/avatar5.jpg';
import Avatar2 from '../assets/images/xs/avatar2.jpg';
import Avatar1 from '../assets/images/xs/avatar1.jpg';
import Avatar3 from '../assets/images/xs/avatar3.jpg';
import BHTPA_LOGO_T from '../assets/images/icons/bhtpa-logo-transparent.png';

import { logout } from '../../src/utils/userUtils';
import { userType, getName } from '../utils/userUtils';
import { TENANT_USER, ADMIN_USER, PD_USER } from '../utils/constants';
import { dashboardURLBasedOnUserType } from '../utils/GeneralUtils';

class NavbarMenu extends React.Component {
  state = {
    linkupdate: false,
  };
  componentDidMount() {
    this.props.tostMessageLoad(true);
    // var res = window.location.pathname;
    // res = res.split("/");
    // res = res.length > 4 ? res[4] : "/";
    const { activeKey } = this.props;
    this.activeMenutabwhenNavigate('/' + activeKey);
    this.setState({ somethi: true });

    console.log('user type: ', userType());
  }

  activeMenutabwhenNavigate(activeKey) {
    if (activeKey === '/dashboard' || activeKey === '/project-profile-dashboard' || activeKey === '/create-project-profile' || activeKey === '/project-schedule-dashboard' || activeKey === '/project-schedule-extend-dashboard') {
      this.activeMenutabContainer('dashboradContainer');
    } else if (activeKey === '/admin-rent-dashaboard' || activeKey === '/create-rent-invoice' || activeKey === '/tenant-rent-dashaboard') {
      this.activeMenutabContainer('managerents');
    } else if (activeKey === '/notice-board-dashboard' || activeKey === '/create-notice-board') {
      this.activeMenutabContainer('manage-notice-board');
    } else if (activeKey === '/tenant-dashboard' || activeKey === '/create-tenant') {
      this.activeMenutabContainer('managetenants');
    } else if (activeKey === '/admin-support-dashboard' || activeKey === '/chat' || activeKey === '/contacts-person' || activeKey === '/add-bhtpa-contact' || activeKey === '/calendar') {
      // this.activeMenutabContainer('ServiceCenterContainer');
    } else if (activeKey === '/project-dashboard') {
      this.activeMenutabContainer('ManageProjectContainer');
    } else if (activeKey === '/support-dashboard' || activeKey === '/create-support') {
      // this.activeMenutabContainer('SupportContainer');
    } else if (activeKey === '/profiledetails' || activeKey === '/updatepassword' || activeKey === '/admin-user-list' || activeKey === '/admin-create-user') {
      this.activeMenutabContainer('ProfileContainer');
    } else if (activeKey === '/project-implemention-dashboard' || activeKey === '/project-implemention-name-list') {
      this.activeMenutabContainer('manageprojectimplementation');
    } else if (
      activeKey === '/operation-dashboard' ||
      activeKey === '/admin-project-schedule-extend-dashboard'
      //OperationContainer
    ) {
      this.activeMenutabContainer('OperationContainer');
    } else if (activeKey === '/login') {
      this.activeMenutabContainer('LogoutContainer');
    } else if (activeKey === '/appinbox' || activeKey === '/appchat' || activeKey === '/appcalendar' || activeKey === '/appcontact') {
      this.activeMenutabContainer('AppContainer');
    } else if (
      activeKey === '/uitypography' ||
      activeKey === '/uitabs' ||
      activeKey === '/uibuttons' ||
      activeKey === '/bootstrapui' ||
      activeKey === '/uiicons' ||
      activeKey === '/uinotifications' ||
      activeKey === '/uicolors' ||
      activeKey === '/uilistgroup' ||
      activeKey === '/uimediaobject' ||
      activeKey === '/uimodal' ||
      activeKey === '/uiprogressbar'
    ) {
      this.activeMenutabContainer('UIElementsContainer');
    } else if (activeKey === '/login') {
      this.activeMenutabContainer('WidgetsContainer');
    } else if (
      activeKey === '/teamsboard' ||
      activeKey === '/profilev2page' ||
      activeKey === '/helperclass' ||
      activeKey === '/searchresult' ||
      activeKey === '/invoicesv2' ||
      activeKey === '/invoices' ||
      activeKey === '/pricing' ||
      activeKey === '/timeline' ||
      activeKey === '/profilev1page' ||
      activeKey === '/blankpage' ||
      activeKey === '/imagegalleryprofile' ||
      activeKey === '/projectslist' ||
      activeKey === '/maintanance' ||
      activeKey === '/testimonials' ||
      activeKey === '/faqs'
    ) {
      this.activeMenutabContainer('PagesContainer');
    } else if (activeKey === '/echart') {
      this.activeMenutabContainer('chartsContainer');
    } else if (activeKey === '/leafletmap') {
      this.activeMenutabContainer('MapsContainer');
    }
  }

  activeMenutabContainer(id) {
    var parents = document.getElementById('main-menu');
    var activeMenu = document.getElementById(id);

    for (let index = 0; index < parents.children.length; index++) {
      if (parents.children[index].id !== id) {
        parents.children[index].classList.remove('active');
        parents.children[index].children[1].classList.remove('in');
      }
    }
    setTimeout(() => {
      activeMenu.classList.toggle('active');
      activeMenu.children[1].classList.toggle('in');
    }, 10);
  }
  render() {
    const { themeColor, sideMenuTab, isToastMessage, activeKey } = this.props;
    document.body.classList.add(themeColor);

    return (
      <div>
        {isToastMessage ? (
          <Toast
            id='toast-container'
            show={isToastMessage}
            onClose={() => {
              this.props.tostMessageLoad(false);
            }}
            className='toast-info toast-top-right'
            autohide={true}
            delay={5000}
          >
            <Toast.Header className='toast-info mb-0'>Hello, welcome to Digital dashboard</Toast.Header>
          </Toast>
        ) : null}
        <nav className='navbar navbar-fixed-top'>
          <div className='container-fluid'>
            <div className='navbar-btn'>
              <button
                className='btn-toggle-offcanvas'
                onClick={() => {
                  this.props.onPressSideMenuToggle();
                }}
              >
                <i className='lnr lnr-menu fa fa-bars'></i>
              </button>
            </div>

            <div className='navbar-brand'>
              <a href={dashboardURLBasedOnUserType()}>
                <img src={BHTPA_LOGO_T} width='116px' height='40px' alt='Background image' />

                <div className='time-picker-container'>
                  {/* <i className="icon-grid black-font-color"></i>
                  <div className="font-size-24px black-font-color bold-font">
                    Dashboard
                  </div> */}
                </div>
              </a>
            </div>

            <div className='navbar-right'>
              <form id='navbar-search' className='navbar-form search-form'>
                <input className='form-control' placeholder='Search here...' type='text' />
                <button type='button' className='btn btn-default'>
                  <i className='icon-magnifier'></i>
                </button>
              </form>

              <div id='navbar-menu'>
                <ul className='nav navbar-nav'>
                  {/* <li className="header-profile-container">
                    <Link onClick={() => {

                      this.props.history.push('/login')

                    }} to={"/login"} className="icon-menu">
                      <img
                        src={ProfileImage}
                        width="30px"
                        height="30px"
                        alt="User Profile Picture"
                      />
                    </Link>
                    <div>Profile name</div>
                  </li> */}
                  <li>
                    <Link
                      onClick={() => {
                        this.props.loginDataReset();
                        this.props.userLogout();
                        logout();
                        this.props.history.push('/login');
                      }}
                      to={'/login'}
                      className='icon-menu'
                    >
                      <i className='icon-login'></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>

        <div id='left-sidebar' className='sidebar' style={{ zIndex: 9 }}>
          <div className='sidebar-scroll'>
            <div className='user-account'>
              <img src={ProfileImage} className='rounded-circle user-photo' alt='User Profile' />
              <Dropdown>
                <span>Welcome,</span>
                <Dropdown.Toggle variant='none' as='a' id='dropdown-basic' className='user-name'>
                  <strong>{getName()}</strong>
                </Dropdown.Toggle>

                <Dropdown.Menu className='dropdown-menu-right account'>
                  <Dropdown.Item href='profiledetails'>
                    <i className='icon-user'></i>My Profile
                  </Dropdown.Item>

                  <li className='divider'></li>
                  <Dropdown.Item
                    onClick={() => {
                      this.props.userLogout();
                      logout();
                      this.props.history.push('/login');
                    }}
                  >
                    {' '}
                    <i className='icon-power'></i>Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <hr />
            </div>

            <div className='tab-content p-l-0 p-r-0'>
              <div className={sideMenuTab[0] ? 'tab-pane active show' : 'tab-pane'} id='menu'>
                <Nav id='left-sidebar-nav' className='sidebar-nav'>
                  <ul id='main-menu' className='metismenu'>
                    {/* Dashboard for admin user*/}
                    {userType() === ADMIN_USER && (
                      <li className='' id='dashboradContainer'>
                        <a
                          href='#!'
                          className='has-arrow'
                          onClick={(e) => {
                            e.preventDefault();
                            this.activeMenutabContainer('dashboradContainer');
                          }}
                        >
                          <i className='icon-home'></i>
                          <span>Home</span>
                        </a>
                        <ul className='collapse'>
                          <li className={activeKey === 'dashboard' ? 'active' : ''}>
                            <Link to='dashboard'>Dashboard</Link>
                          </li>
                        </ul>
                      </li>
                    )}

                    {/* Dashboard for tenant user */}
                    {userType() === TENANT_USER && (
                      <li className='' id='dashboradContainer'>
                        <a
                          href='#!'
                          className='has-arrow'
                          onClick={(e) => {
                            e.preventDefault();
                            this.activeMenutabContainer('dashboradContainer');
                          }}
                        >
                          <i className='icon-home'></i>
                          <span>Home</span>
                        </a>
                        <ul className='collapse'>
                          <li className={activeKey === 'tenant-user-dashboard' ? 'active' : ''}>
                            <Link to='tenant-user-dashboard'>Dashboard</Link>
                          </li>
                          <li className={activeKey === 'project-profile-dashboard' ? 'active' : ''}>
                            <Link to='project-profile-dashboard'>Investor profiles</Link>
                          </li>
                          <li className={activeKey === 'project-schedule-dashboard' ? 'active' : ''}>
                            <Link to='project-schedule-dashboard'>Work schedule</Link>
                          </li>
                          <li className={activeKey === 'project-schedule-extend-dashboard' ? 'active' : ''}>
                            <Link to='project-schedule-extend-dashboard'>Extend work schedule</Link>
                          </li>
                        </ul>
                      </li>
                    )}

                    {/* Dashboard for project director(PD) user */}
                    {userType() === PD_USER && (
                      <li className='' id='dashboradContainer'>
                        <a
                          href='#!'
                          className='has-arrow'
                          onClick={(e) => {
                            e.preventDefault();
                            this.activeMenutabContainer('dashboradContainer');
                          }}
                        >
                          <i className='icon-home'></i>
                          <span>Home</span>
                        </a>
                        <ul className='collapse'>
                          <li className={activeKey === 'pd-user-dashboard' ? 'active' : ''}>
                            <Link to='pd-user-dashboard'>Dashboard</Link>
                          </li>
                          {/* <li className={activeKey === "project-implemention-details-update" ? "active" : ""}
                        >
                          <Link to="project-implemention-details-update">Project Impl. update</Link>
                        </li> */}
                        </ul>
                      </li>
                    )}

                    {userType() === TENANT_USER && (
                      <li className='' id='managerents'>
                        <a
                          href='#!'
                          className='has-arrow'
                          onClick={(e) => {
                            e.preventDefault();
                            this.activeMenutabContainer('managerents');
                          }}
                        >
                          <i className='icon-grid'></i> <span>Rents</span>
                        </a>
                        <ul className='collapse'>
                          <li className={activeKey === 'tenant-rent-dashaboard' ? 'active' : ''}>
                            <Link to='tenant-rent-dashaboard'>Dashboard</Link>
                          </li>
                        </ul>
                      </li>
                    )}

                    {/* {userType() === TENANT_USER && (
                      <li className='' id='SupportContainer'>
                        <a
                          href='#!'
                          className='has-arrow'
                          onClick={(e) => {
                            e.preventDefault();
                            this.activeMenutabContainer('SupportContainer');
                          }}
                        >
                          <i className='icon-grid'></i>
                          <span>Support</span>
                        </a>
                        <ul className='collapse'>
                          <li className={activeKey === 'support-dashboard' ? 'active' : ''}>
                            <Link to='support-dashboard'>Dashboard</Link>
                          </li>
                          <li className={activeKey === 'create-support' ? 'active' : ''}>
                            <Link to='create-support'>Create support</Link>
                          </li>
                        </ul>
                      </li>
                    )} */}

                    {userType() === ADMIN_USER && (
                      <li className='' id='manageprojectimplementation'>
                        <a
                          href='#!'
                          className='has-arrow'
                          onClick={(e) => {
                            e.preventDefault();
                            this.activeMenutabContainer('manageprojectimplementation');
                          }}
                        >
                          <i className='icon-grid'></i> <span>Project progress report</span>
                        </a>
                        <ul className='collapse'>
                          <li className={activeKey === 'project-implemention-dashboard' ? 'active' : ''}>
                            <Link to='project-implemention-dashboard'>Dashboard</Link>
                          </li>
                          <li className={activeKey === 'project-implemention-name-list' ? 'active' : ''} onClick={() => {}}>
                            <Link to='project-implemention-name-list'>Project name list</Link>
                          </li>
                        </ul>
                      </li>
                    )}

                    {userType() === ADMIN_USER && (
                      <li className='' id='managetenants'>
                        <a
                          href='#!'
                          className='has-arrow'
                          onClick={(e) => {
                            e.preventDefault();
                            this.activeMenutabContainer('managetenants');
                          }}
                        >
                          <i className='icon-grid'></i> <span>Investor accounts</span>
                        </a>
                        <ul className='collapse'>
                          <li className={activeKey === 'tenant-admin-dashboard' ? 'active' : ''}>
                            <Link to='tenant-admin-dashboard'>Dashboard</Link>
                          </li>
                          <li className={activeKey === 'create-tenant' ? 'active' : ''} onClick={() => {}}>
                            <Link to='create-tenant'>Create investor account</Link>
                          </li>
                        </ul>
                      </li>
                    )}

                    {userType() === ADMIN_USER && (
                      <li className='' id='ManageProjectContainer'>
                        <a
                          href='#!'
                          className='has-arrow'
                          onClick={(e) => {
                            e.preventDefault();
                            this.activeMenutabContainer('ManageProjectContainer');
                          }}
                        >
                          <i className='icon-grid'></i> <span>Investor profiles</span>
                        </a>
                        <ul className='collapse'>
                          <li className={activeKey === 'project-dashboard' ? 'active' : ''}>
                            <Link to='project-dashboard'>Dashboard</Link>
                          </li>
                        </ul>
                      </li>
                    )}

                    {userType() === ADMIN_USER && (
                      <li id='OperationContainer' className=''>
                        <a
                          href='#!'
                          className='has-arrow'
                          onClick={(e) => {
                            e.preventDefault();
                            this.activeMenutabContainer('OperationContainer');
                          }}
                        >
                          <i className='icon-grid'></i> <span>Work schedule</span>
                        </a>
                        <ul className='collapse'>
                          <li className={activeKey === 'operation-dashboard' ? 'active' : ''} onClick={() => {}}>
                            <Link to='operation-dashboard'>Dashboard</Link>
                          </li>

                          <li className={activeKey === 'admin-project-schedule-extend-dashboard' ? 'active' : ''} onClick={() => {}}>
                            <Link to='admin-project-schedule-extend-dashboard'>Schedule extend dashboard </Link>
                          </li>
                        </ul>
                      </li>
                    )}

                    {userType() === ADMIN_USER && (
                      <li className='' id='managerents'>
                        <a
                          href='#!'
                          className='has-arrow'
                          onClick={(e) => {
                            e.preventDefault();
                            this.activeMenutabContainer('managerents');
                          }}
                        >
                          <i className='icon-grid'></i> <span>Rents</span>
                        </a>
                        <ul className='collapse'>
                          <li className={activeKey === 'admin-rent-dashaboard' ? 'active' : ''}>
                            <Link to='admin-rent-dashaboard'>Dashboard</Link>
                          </li>
                          <li className={activeKey === 'create-rent-invoice' ? 'active' : ''}>
                            <Link to='create-rent-invoice'>Create invoice</Link>
                          </li>
                        </ul>
                      </li>
                    )}

                    {/* {userType() === ADMIN_USER && (
                      <li id='ServiceCenterContainer' className=''>
                        <a
                          href='#!'
                          className='has-arrow'
                          onClick={(e) => {
                            e.preventDefault();
                            this.activeMenutabContainer('ServiceCenterContainer');
                          }}
                        >
                          <i className='icon-grid'></i> <span>Service center</span>
                        </a>
                        <ul className='collapse'>
                          <li className={activeKey === 'admin-support-dashboard' ? 'active' : ''} onClick={() => {}}>
                            <Link to='admin-support-dashboard'>Support dashboard</Link>
                          </li>
                          <li className={activeKey === 'contacts-person' ? 'active' : ''} onClick={() => {}}>
                            <Link to='contacts-person'>Contacts person</Link>
                          </li>
                          <li className={activeKey === 'add-bhtpa-contact' ? 'active' : ''} onClick={() => {}}>
                            <Link to='add-bhtpa-contact'>Add contact</Link>
                          </li>
                        </ul>
                      </li>
                    )} */}

                    {
                      <li className='' id='manage-notice-board'>
                        <a
                          href='#!'
                          className='has-arrow'
                          onClick={(e) => {
                            e.preventDefault();
                            this.activeMenutabContainer('manage-notice-board');
                          }}
                        >
                          <i className='icon-grid'></i> <span>Notice board</span>
                        </a>
                        <ul className='collapse'>
                          <li className={activeKey === 'notice-board-dashboard' ? 'active' : ''}>
                            <Link to='notice-board-dashboard'>All notifications</Link>
                          </li>

                          {userType() === ADMIN_USER && (
                            <li className={activeKey === 'create-notice-board' ? 'active' : ''}>
                              <Link to='create-notice-board'>Create notice board</Link>
                            </li>
                          )}
                        </ul>
                      </li>
                    }

                    <li id='ProfileContainer' className=''>
                      <a
                        href='#!'
                        className='has-arrow'
                        onClick={(e) => {
                          e.preventDefault();
                          this.activeMenutabContainer('ProfileContainer');
                        }}
                      >
                        <i className='icon-grid'></i> <span>Manage profile</span>
                      </a>
                      <ul className='collapse'>
                        <li className={activeKey === 'profiledetails' ? 'active' : ''} onClick={() => {}}>
                          <Link to='profiledetails'>Profile details</Link>
                        </li>
                        <li className={activeKey === 'updatepassword' ? 'active' : ''} onClick={() => {}}>
                          <Link to='updatepassword'>Change password</Link>
                        </li>

                        {userType() === ADMIN_USER && (
                          <>
                            <li className={activeKey === 'admin-user-list' ? 'active' : ''} onClick={() => {}}>
                              <Link to='admin-user-list'>User list</Link>
                            </li>
                            <li className={activeKey === 'admin-create-user' ? 'active' : ''} onClick={() => {}}>
                              <Link to='admin-create-user'>Create new user</Link>
                            </li>
                          </>
                        )}
                      </ul>
                    </li>

                    <li id='LogoutContainer' className=''>
                      <a
                        href='#!'
                        className='has-arrow'
                        onClick={(e) => {
                          e.preventDefault();
                          this.activeMenutabContainer('LogoutContainer');
                        }}
                      >
                        <i className='icon-login'></i> <span>Log out</span>
                      </a>
                      <ul className='collapse'>
                        <Link
                          onClick={() => {
                            this.props.userLogout();
                            logout();
                          }}
                          to='login'
                        >
                          Log out
                        </Link>
                      </ul>
                    </li>
                  </ul>
                </Nav>
              </div>
              <div className={sideMenuTab[1] ? 'tab-pane p-l-15 p-r-15 show active' : 'tab-pane p-l-15 p-r-15'} id='Chat'>
                <form>
                  <div className='input-group m-b-20'>
                    <div className='input-group-prepend'>
                      <span className='input-group-text'>
                        <i className='icon-magnifier'></i>
                      </span>
                    </div>
                    <input type='text' className='form-control' placeholder='Search...' />
                  </div>
                </form>
                <ul className='right_chat list-unstyled'>
                  <li className='online'>
                    <a>
                      <div className='media'>
                        <img className='media-object ' src={Avatar4} alt='' />
                        <div className='media-body'>
                          <span className='name'>Chris Fox</span>
                          <span className='message'>Designer, Blogger</span>
                          <span className='badge badge-outline status'></span>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className='online'>
                    <a>
                      <div className='media'>
                        <img className='media-object ' src={Avatar5} alt='' />
                        <div className='media-body'>
                          <span className='name'>Joge Lucky</span>
                          <span className='message'>Java Developer</span>
                          <span className='badge badge-outline status'></span>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className='offline'>
                    <a>
                      <div className='media'>
                        <img className='media-object ' src={Avatar2} alt='' />
                        <div className='media-body'>
                          <span className='name'>Isabella</span>
                          <span className='message'>CEO, Thememakker</span>
                          <span className='badge badge-outline status'></span>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className='offline'>
                    <a>
                      <div className='media'>
                        <img className='media-object ' src={Avatar1} alt='' />
                        <div className='media-body'>
                          <span className='name'>Folisise Chosielie</span>
                          <span className='message'>Art director, Movie Cut</span>
                          <span className='badge badge-outline status'></span>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className='online'>
                    <a>
                      <div className='media'>
                        <img className='media-object ' src={Avatar3} alt='' />
                        <div className='media-body'>
                          <span className='name'>Alexander</span>
                          <span className='message'>Writter, Mag Editor</span>
                          <span className='badge badge-outline status'></span>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NavbarMenu.propTypes = {
  addClassactive: PropTypes.array.isRequired,
  addClassactiveChild: PropTypes.array.isRequired,
  addClassactiveChildApp: PropTypes.array.isRequired,
  addClassactiveChildFM: PropTypes.array.isRequired,
  addClassactiveChildBlog: PropTypes.array.isRequired,
  addClassactiveChildUI: PropTypes.array.isRequired,
  addClassactiveChildWidgets: PropTypes.array.isRequired,
  addClassactiveChildAuth: PropTypes.array.isRequired,
  addClassactiveChildPages: PropTypes.array.isRequired,
  addClassactiveChildForms: PropTypes.array.isRequired,
  addClassactiveChildTables: PropTypes.array.isRequired,
  addClassactiveChildChart: PropTypes.array.isRequired,
  addClassactiveChildMaps: PropTypes.array.isRequired,
  themeColor: PropTypes.string.isRequired,
  generalSetting: PropTypes.array.isRequired,
  toggleNotification: PropTypes.bool.isRequired,
  toggleEqualizer: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ navigationReducer }) => {
  const {
    addClassactive,
    addClassactiveChild,
    addClassactiveChildApp,
    addClassactiveChildFM,
    addClassactiveChildBlog,
    addClassactiveChildUI,
    addClassactiveChildWidgets,
    addClassactiveChildAuth,
    addClassactiveChildPages,
    addClassactiveChildForms,
    addClassactiveChildTables,
    addClassactiveChildChart,
    addClassactiveChildMaps,
    themeColor,
    generalSetting,
    toggleNotification,
    toggleEqualizer,
    menuProfileDropdown,
    sideMenuTab,
    isToastMessage,
  } = navigationReducer;
  return {
    addClassactive,
    addClassactiveChild,
    addClassactiveChildApp,
    addClassactiveChildFM,
    addClassactiveChildBlog,
    addClassactiveChildUI,
    addClassactiveChildWidgets,
    addClassactiveChildAuth,
    addClassactiveChildPages,
    addClassactiveChildForms,
    addClassactiveChildTables,
    addClassactiveChildChart,
    addClassactiveChildMaps,
    themeColor,
    generalSetting,
    toggleNotification,
    toggleEqualizer,
    menuProfileDropdown,
    sideMenuTab,
    isToastMessage,
  };
};

export default connect(mapStateToProps, {
  onPressDashbord,
  onPressDashbordChild,
  onPressThemeColor,
  onPressGeneralSetting,
  onPressNotification,
  onPressEqualizer,
  onPressSideMenuToggle,
  onPressMenuProfileDropdown,
  onPressSideMenuTab,
  tostMessageLoad,
  userLogout,
  loginDataReset,
})(NavbarMenu);
