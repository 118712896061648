import React from 'react';
import { useHistory } from 'react-router';
import { TENANT_USER, ADMIN_USER } from '../../utils/constants';
import Button from 'react-bootstrap/Button';
import { useFetch } from '../../customHooks/fetchData';
import { getProjectScheduleExtendRequestListURL } from '../../utils/allurls';
import { userId } from '../../utils/userUtils';
import { useSelector, useDispatch } from 'react-redux';
import { getProjectUpdateJsonBody } from '../../utils/GeneralUtils';
import { updateProjectScheduleExtendRequestStatus, deleteProjectScheduleExtendRequest } from '../../actions/projectScheduleExtendRequestAction';
import swal from 'sweetalert';

export default function ProjectScheduleExtendRequestList(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const projectScheduleExtendRequestReducer = useSelector((state) => state.projectScheduleExtendRequestReducer);

  var requestURL;
  if (props.user_type === TENANT_USER) {
    requestURL = getProjectScheduleExtendRequestListURL(userId(), '');
  } else if (props.user_type === ADMIN_USER) {
    requestURL = getProjectScheduleExtendRequestListURL('', '');
  }

  const { loading, data, success } = useFetch(requestURL);

  const getProjectExtendRequestStatus = (status) => {
    var statusView;

    if (status === 'completed') {
      statusView = <span className='badge badge-success bg-success text-white'>{status}</span>;
    } else if (status === 'approved') {
      statusView = <span className='badge badge-danger bg-info text-white'>{status}</span>;
    } else if (status === 'pending') {
      statusView = <span className='badge badge-danger bg-warning text-white'>{status}</span>;
    } else {
      statusView = <span className='badge badge-danger bg-warning text-white'>{status}</span>;
    }

    return statusView;
  };

  const deleteScheduleExtendRequest = (extendRequestId) => {
    dispatch(deleteProjectScheduleExtendRequest(extendRequestId));
  };

  const projectUpdateSuccess = (success) => {
    if (success) {
      swal('Work support update status', 'Updated succesfully', 'success').then(function () {
        window.location.reload(false);
      });
    }
  };

  const projectDeleteSuccess = (success) => {
    if (success) {
      swal('Work support delete status', 'Work support deleted successfully', 'success').then(function () {
        window.location.reload(false);
      });
    }
  };

  return (
    <>
      <div className='col-lg-12'>
        <div className='card product_item_list product-order-list'>
          <div className='header align-space-between-side'>
            <h2>Work schedule extend request list</h2>
            {props.user_type === TENANT_USER && (
              <Button
                onClick={(e) => {
                  history.push('request-project-schedule-extend');
                }}
                variant='primary'
              >
                {'Reqeust work schedule extend'}
              </Button>
            )}
          </div>
          <div className='body'>
            <div className='table-responsive'>
              <table className='table table-hover m-b-0'>
                <thead className='thead-dark'>
                  <tr>
                    <th>ID</th>
                    <th>Investor name</th>
                    <th data-breakpoints='sm xs'>Schedule Name</th>
                    <th>Cause title</th>
                    <th>Cause details</th>
                    <th data-breakpoints='xs'>Extend date</th>
                    <th data-breakpoints='sm xs md'>Status</th>
                    <th data-breakpoints='xs md'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loading && 'Loading...'}
                  {success &&
                    data.data.map((project, index) => (
                      <tr key={index}>
                        <td>{project.id}</td>
                        <td>{project.project_name}</td>
                        <td>{project.schedule_name}</td>
                        <td>
                          <div class='request-top' style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                            <p>{project.cause_title}</p>
                          </div>
                        </td>
                        <td>
                          <div class='request-top' style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                            <p>{project.cause_details}</p>
                          </div>
                        </td>
                        <td>{project.extend_date}</td>
                        <td>{getProjectExtendRequestStatus(project.approval_status)}</td>
                        {props.user_type === TENANT_USER && (
                          <td>
                            {/* <a className="btn btn-outline-secondary mr-1">
                                           <i className="icon-pencil"></i>
                                       </a> */}
                            {project.approval_status === 'pending' && (
                              <a className='btn btn-outline-secondary'>
                                <i
                                  className='icon-trash'
                                  onClick={() => {
                                    deleteScheduleExtendRequest(project.id);
                                  }}
                                ></i>
                              </a>
                            )}
                          </td>
                        )}

                        {props.user_type === ADMIN_USER && (
                          <td>
                            {project.approval_status === 'pending' && (
                              <Button
                                onClick={() => {
                                  const jsonBody = getProjectUpdateJsonBody(project.id, 'approved');

                                  dispatch(
                                    updateProjectScheduleExtendRequestStatus({
                                      ...jsonBody,
                                      schedule_id: project.schedule_id,
                                      extend_date: project.extend_date,
                                    })
                                  );
                                }}
                              >
                                {'Approve'}
                              </Button>
                            )}

                            <a className='btn btn-outline-secondary'>
                              <i
                                className='icon-trash'
                                onClick={() => {
                                  deleteScheduleExtendRequest(project.id);
                                }}
                              ></i>
                            </a>
                          </td>
                        )}

                        {projectUpdateSuccess(projectScheduleExtendRequestReducer.updateSuccess)}
                        {projectDeleteSuccess(projectScheduleExtendRequestReducer.deleteSuccess)}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>{' '}
    </>
  );
}
