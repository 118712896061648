import React from 'react';
import { useHistory } from 'react-router';
import { PD_USER, ADMIN_USER } from '../../utils/constants';
import Button from 'react-bootstrap/Button';
import { useFetch } from '../../customHooks/fetchData';
import { getProjectImplementationDetailsListURL } from '../../utils/allurls';
import { userId } from '../../utils/userUtils';
import { getMonthYearFromDate, getTotal, getPercentage } from '../../utils/liteUtils';
import { dateStringFull } from '../../utils/DateUtils';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

export default function ProjectImplementationDetailsList(props) {
  const history = useHistory();

  var requestURL;
  if (props.user_type === PD_USER) {
    requestURL = getProjectImplementationDetailsListURL(userId(), '', '', '');
  } else if (props.user_type === ADMIN_USER) {
    requestURL = getProjectImplementationDetailsListURL('', '', '', '');
  }

  const { loading, data, success } = useFetch(requestURL);

  return (
    <>
      <div className='col-lg-12'>
        <div className='card product_item_list product-order-list'>
          <div className='header align-space-between-side'>
            <h2>Project implementation progress details</h2>
            <span>* All figure in lakh taka</span>
            {success && data.data.length > 0 && props.user_type === PD_USER && (
              <Button
                onClick={(e) => {
                  history.push({ pathname: 'project-implemention-details-update', state: { projectDetails: data.data[0] } });
                }}
                variant='primary'
              >
                {'Update project impl. details'}
              </Button>
            )}
          </div>
          <div style={{ fontSize: '12px' }} className='body '>
            <div className='table-responsive'>
              <table className='table table-hover m-b-0'>
                <thead className='thead-dark'>
                  <tr>
                    <th data-breakpoints='sm xs'>Sl.</th>
                    <th>Project name &amp; duration</th>
                    <th>Total project cost</th>
                    <th>Till last fiscal year cost</th>

                    <th>Allotment GOB</th>
                    <th>GOB aid</th>
                    <th>Total alloted GOB</th>
                    <th>Release GOB</th>
                    <th>Release project aid</th>
                    <th>Total release GOB</th>
                    <th>GOB(%)</th>
                    <th>Expense GOB</th>
                    <th>Expense aid</th>
                    <th>Total expense GOB</th>
                    <th>Current year financial progress(%)</th>
                    <th>Current year actual progress(%)</th>
                    <th>Accumulated total expense</th>
                    <th>Accumulated total financial progress(%)</th>
                    <th>Accumulated total actual progress(%)</th>
                    <th>Last updated</th>
                    {props.user_type === ADMIN_USER && <th>Responsible person</th>}
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className='custom-table'>
                  {loading && (
                    <tr>
                      <td>Loading...</td>
                    </tr>
                  )}

                  {success &&
                    data.data.map((project, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          history.push({ pathname: 'project-implementation-package-list', state: { id: project.project_impl_id, name: project.project_name, year: project.year } });
                        }}
                      >
                        <td>
                          <div className='request-top' style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                            <p>{index + 1}</p>
                          </div>
                        </td>
                        <td>
                          <p style={{ width: '224px', whiteSpace: 'break-spaces' }}>
                            {project.project_name} &amp; {`${getMonthYearFromDate(project.start_date)} to ${getMonthYearFromDate(project.end_date)} `}
                          </p>
                        </td>
                        <td>{project.total_expense}</td>
                        <td>{project.till_last_fiscal_year_expense}</td>

                        <td>{project.radp_alloted_gob}</td>
                        <td>{project.alloted_gob_aid}</td>
                        <td>{getTotal(project.radp_alloted_gob, project.alloted_gob_aid)}</td>

                        <td>{project.release_gob}</td>
                        <td>{project.release_gob_aid}</td>
                        <td>{getTotal(project.release_gob, project.release_gob_aid)}</td>
                        <td>{getPercentage(getTotal(project.release_gob, project.release_gob_aid), getTotal(project.radp_alloted_gob, project.alloted_gob_aid))}%</td>
                        <td>{project.expended_gob}</td>
                        <td>{project.expended_gob_aid}</td>
                        <td>{getTotal(project.expended_gob, project.expended_gob_aid)}</td>
                        <td>{getPercentage(getTotal(project.expended_gob, project.expended_gob_aid), getTotal(project.radp_alloted_gob, project.alloted_gob_aid))}%</td>
                        <td>{project.current_year_actual_progress}%</td>
                        <td>{getTotal(project.till_last_fiscal_year_expense, getTotal(project.expended_gob, project.expended_gob_aid))}</td>
                        <td>{getPercentage(getTotal(project.till_last_fiscal_year_expense, getTotal(project.expended_gob, project.expended_gob_aid)), project.total_expense)}%</td>
                        <td>{project.accumulated_actual_progress}%</td>
                        <td>{project.last_update ? dateStringFull(project.last_update) : 'NA'}</td>
                        {props.user_type === ADMIN_USER && (
                          <td>
                            <div style={{ fontSize: '14px' }}>{project.usr_name}</div>
                            <div>{project.description}</div>
                          </td>
                        )}
                        {props.user_type === PD_USER && (
                          <td>
                            <Button
                              variant='primary'
                              onClick={(event) => {
                                event.stopPropagation();

                                history.push({ pathname: 'project-implemention-details-update', state: { projectDetails: project } });
                              }}
                            >
                              {'Edit'}
                            </Button>
                          </td>
                        )}
                        <td>
                          <Button
                            variant='secondary'
                            onClick={() => {
                              history.push({ pathname: 'project-implementation-package-list', state: { id: project.project_impl_id, name: project.project_name, year: project.year } });
                            }}
                          >
                            {'Package details'}
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {success && data.data.length === 0 && (
                <Alert style={{ margin: '10px' }} severity='info'>
                  <AlertTitle>Information</AlertTitle>
                  No project is assigned to you yet! Please contact the administrator.
                </Alert>
              )}
            </div>
          </div>
        </div>
      </div>{' '}
    </>
  );
}
