import React from 'react';
import { useDispatch } from 'react-redux';
import PageHeader from '../../components/PageHeader';
import PieChart from '../../components/Charts/PieChart';
import ProjectProfileSummary from '../TenantDashboard/ProjectProfileSummary';
import { ADMIN_USER } from '../../utils/constants';
import { useFetch } from '../../customHooks/fetchData';
import { getDashboardDataURL } from '../../utils/allurls';
import { getTenantDashboardDataList, getDashboardPiechartData } from '../../utils/GeneralUtils';
import SupportStatsSummary from '../TenantDashboard/SupportStatsSummary';
import ScheduleStatsSummary from '../TenantDashboard/ScheduleStatsSummary';
import ExtensionStatsSummary from '../TenantDashboard/ExtensionStatsSummary';
import RentStatsSummary from '../TenantDashboard/RentStatsSummary';
import ProjectImplementationProgressReport from '../TenantDashboard/ProjectImplementationProgressReport';
import MakeWrapper from '../TenantDashboard/MakeWrapper';
import { useHistory } from 'react-router';

export default function Dashbord() {
  const dashboardData = useFetch(getDashboardDataURL('project-profile'));
  const history = useHistory();

  const dispatch = useDispatch();

  return (
    <div className='dashboard-container pb-5'>
      <PageHeader HeaderText='Dashboard' Breadcrumb={[{ name: 'Dashboard' }]} />

      <div className={`dashboard-container-summary`}>
        <div className='mb-5 w-100'>
          <ProjectImplementationProgressReport user_type={ADMIN_USER} />
        </div>
        {/* <div className='mb-5 w-100'>
          <MakeWrapper label='Support'>
            <div className='w-100 d-flex flex-wrap'>
              <SupportStatsSummary user_type={ADMIN_USER} />
            </div>
          </MakeWrapper>
        </div> */}
        <div onClick={() => history.replace('operation-dashboard')} className='mb-5 w-100' style={{ cursor: 'pointer' }}>
          <MakeWrapper label='Schedule'>
            <div className='w-100 d-flex flex-wrap'>
              <ScheduleStatsSummary user_type={ADMIN_USER} />
            </div>
          </MakeWrapper>
        </div>
        <div className='mb-5 w-100'>
          <MakeWrapper label='Extension Request '>
            <div onClick={() => history.replace('admin-project-schedule-extend-dashboard')} style={{ cursor: 'pointer' }} className='w-100 d-flex flex-wrap'>
              <ExtensionStatsSummary user_type={ADMIN_USER} />
            </div>
          </MakeWrapper>
        </div>
      </div>
      <div className={`dashboard-container-summary`}></div>
    </div>
  );
}
