import React, { useEffect, useState } from 'react';
import { TENANT_USER, ADMIN_USER } from '../../utils/constants';
import { useFetch } from '../../customHooks/fetchData';
import { getDashboardDataURL } from '../../utils/allurls';
import { getTenantDashboardDataList, getDashboardPiechartData } from '../../utils/GeneralUtils';
import SparkleCard from '../../components/SparkleCard';
import axios from 'axios';
import { BASE_HEADERS, BASE_URL } from '../../utils/baseValues';
import './ProjectImplementationProgressReport.css';
import MakeWrapper from './MakeWrapper';
import { getRandomsparklineData } from '../../utils/dashboardDatastore';
import { useHistory } from 'react-router';

const url = `${BASE_URL}/dashboard-api/all?get=running-project-details,operational-project-details`;

export default function ProjectImplementationProgressReport(props) {
  const [dashboardData, updateDashboardData] = useState({});
  const history = useHistory();

  const getData = async () => {
    const headers = BASE_HEADERS();
    const resp = await axios.get(url, { headers });
    if (resp === 200 || true) {
      updateDashboardData(resp.data.data);
    }
  };

  console.log(dashboardData);

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className='w-100'>
      <div className='w-100'>
        {/* <h6 className='mb-2'>Running Project</h6> */}
        <MakeWrapper label='Running Project'>
          <div onClick={() => history.replace('project-implemention-dashboard')} className='w-100 d-flex flex-wrap ' style={{ cursor: 'pointer' }}>
            <RunningProject project={dashboardData?.running_project_details || {}} />
          </div>
        </MakeWrapper>
      </div>
      {dashboardData?.operational_project_details?.length > 0 && (
        <div className='mt-5 w-100'>
          <MakeWrapper label='Operational Project'>
            <div onClick={() => history.replace('project-dashboard')} className='w-100 d-flex flex-wrap' style={{ cursor: 'pointer' }}>
              <OperationalProject projects={dashboardData.operational_project_details} />
            </div>
          </MakeWrapper>
        </div>
      )}

      {/* {dashboardData?.operational_project_details_summary?.length > 0 && (
        <div className='mt-5 w-100'>
          <MakeWrapper label='Operational Project'>
            <div className='w-100 d-flex flex-wrap'>
              <OperationalProject projects={dashboardData.operational_project_details_summary} />
            </div>
          </MakeWrapper>
        </div>
      )} */}
    </div>
  );
}

export function RunningProject({ project }) {
  const temData = [
    { label: 'Total Running Project', value: project?.running_project_number || 0, ...getRandomsparklineData() },
    { label: 'Total Expense (in Lac)', value: project?.all_running_total_expense || 0, ...getRandomsparklineData() },
    { label: 'Total Expense In Year (in Lac)', value: project?.all_running_total_till_year_expense || 0, ...getRandomsparklineData() },
    { label: 'Financial Progress (%)', value: project?.avg_financial_progress || 0, ...getRandomsparklineData() },
    { label: 'Physical Progress (%)', value: project?.avg_physical_progress || 0, ...getRandomsparklineData() },
  ];
  return (
    <>
      {temData?.map((item, i) => (
        <SparkleCard index={i + 6000} key={i + 6000} Heading={item.label} Money={`${item.value}`} PerText={''} isRandomUpdate={false} mainData={item.data} chartColor={item.areaStyle.color} ContainerClass='w-20 p-1' />
      ))}
    </>
  );
}

export function OperationalProject({ projects = [] }) {
  const bills = projects?.map((it) => parseInt(it.due_bill || 0)).reduce((a, b) => a + b);
  const employments = projects?.map((it) => parseInt(it.employment || 0)).reduce((a, b) => a + b);
  const investments = projects?.map((it) => parseInt(it.investment || 0)).reduce((a, b) => a + b);
  const investors = projects?.map((it) => parseInt(it.number_of_investor || 0)).reduce((a, b) => a + b);
  const tempData = [
    { label: 'Number of Investor', value: investors || 0, ...getRandomsparklineData() },
    { label: 'Total Employment', value: employments || 0, ...getRandomsparklineData() },
    { label: 'Total Investment (Tk)', value: investments || 0, ...getRandomsparklineData() },
    { label: 'Due Bill (Tk)', value: bills || 0, ...getRandomsparklineData() },
  ];

  return (
    <>
      {tempData?.map((item, i) => (
        <SparkleCard index={i + 7000} key={i + 7000} Heading={item.label} Money={`${item.value}`} PerText={''} isRandomUpdate={false} mainData={item.data} chartColor={item.areaStyle.color} ContainerClass='col-lg-3 col-md-6 col-sm-6 p-1' />
      ))}
    </>
  );
}

export function CartItem({ label, value }) {
  return (
    <div className='card-item'>
      <p>{label}</p>
      <h4>{value}</h4>
    </div>
  );
}

export function OpCartItem({ project }) {
  return (
    <div style={{ width: '45%', margin: '5px' }}>
      <div className='header'>{project.park_name}</div>
      <div className='op-card-item'>
        <div className='d-flex w-100'>
          <CartItem label='Number of Investor' value={project?.number_of_investor} />
          <CartItem label='Total Employment' value={project?.employment} />
          <CartItem label='Total Investment (Tk)' value={project?.investment} />
          <CartItem label='Due Bill (Tk)' value={project.due_bill} />
        </div>
      </div>
    </div>
  );
}

// export default function ProjectImplementationProgressReport(props) {

//     var requestURL = "project-progress"

//     if (props.user_type === TENANT_USER) {
//         requestURL = "project-progress"
//     }
//     const dashboardData = useFetch(getDashboardDataURL(requestURL))

//     return (
//         <>
//             {dashboardData.success && getTenantDashboardDataList(dashboardData.data.data).map((item, i) => {
//                 return <SparkleCard
//                     index={i}
//                     key={i}
//                     Heading={item.name}
//                     Money={`${item.value}${item.valueType}`}
//                     PerText={""}
//                     isRandomUpdate={false}
//                     mainData={item.sparklineData.data}
//                     chartColor={item.sparklineData.areaStyle.color}
//                     ContainerClass="col-lg-3 col-md-6 col-sm-6"
//                 />
//             })}
//         </>
//     )
// }
